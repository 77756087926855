import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import i18n from '../../i18n';

import Availability from '../../models/Availability';

import Tooltip from '../widget/Tooltip';

export default class CalendarCaptionHomeView extends React.PureComponent {
    static propTypes = {
        showReserved: PropTypes.bool,
        isOwner: PropTypes.bool,
        userFirstName: PropTypes.string
    };

    static defaultProps = {
        showReserved: false,
        isOwner: false
    };

    render() {
        return (
            <div ref="caption" className="calendar-caption-home-view">
                <div className="bottom-container">
                    <div className="availability-types">
                        {(() =>
                            Availability.availabilitiesTypes.map((availabilitiesType) => {
                                const availabilityTypeClassesDesktop = classNames({
                                    'availability-type-preview': true,
                                    [`${availabilitiesType.className}`]: true
                                });

                                return (
                                    <div key={availabilitiesType.type} className="availability-type">
                                        <div className={availabilityTypeClassesDesktop} />

                                        <div className="availability-type-caption">
                                            <h4>
                                                {i18n.t(availabilitiesType.title)}
                                                <Tooltip
                                                    placement="bottom"
                                                    title={
                                                        this.props.isOwner
                                                            ? i18n.t(availabilitiesType.caption)
                                                            : i18n.t(availabilitiesType.captionGuest, {
                                                                  firstname: this.props.userFirstName
                                                              })
                                                    }
                                                >
                                                    <i className="icon-circle-help" />
                                                </Tooltip>
                                            </h4>
                                        </div>
                                    </div>
                                );
                            }))()}
                    </div>
                </div>
            </div>
        );
    }
}
