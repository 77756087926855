import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '../../i18n';

import Availability from '../../models/Availability';
import Home from '../../models/Home';

import BlockCalendar from '../calendar/BlockCalendar';
import Loading from '../common/Loading';
import CalendarCaptionHomeView from '../calendar/CalendarCaptionHomeView';

export class HomeViewCalendar extends React.Component {
    static propTypes = {
        homeId: PropTypes.number.isRequired,
        home: PropTypes.instanceOf(Home),
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
        hasReservedAvailability: PropTypes.bool,
        isMyHome: PropTypes.bool
    };

    state = {
        nbMonths: 6
    };

    maxNbMonths = 36;

    constructor(props) {
        super(props);
        this.showMoreMonths = this.showMoreMonths.bind(this);
    }

    showMoreMonths() {
        // 36 = 3 years max
        if (this.state.nbMonths < this.maxNbMonths) {
            this.setState({
                nbMonths: this.state.nbMonths + 6
            });
        }
    }

    render() {
        if (!this.props.home || !this.props.availabilities) {
            return <Loading />;
        }
        const minimumNumberOfNights = this.props.home.getMinNights();
        return (
            <div>
                {(() => {
                    if (
                        this.props.home &&
                        this.props.home.get('contact_allowed') === Home.CONTACT_NOT_ALLOWED_ON_UNAVAILABLE
                    ) {
                        return (
                            <div className="home-calendar-only-available-periods">
                                <div className="alert alert-warning">
                                    {i18n.t('home:calendar.only_available_periods')}
                                </div>
                            </div>
                        );
                    }
                })()}

                {minimumNumberOfNights && (
                    <div className="margin-bottom-24">
                        <div className="info-block">
                            <div className="info-block-container">
                                <div className="info-block-icon icon-min-nights"></div>
                            </div>
                            <div
                                className="info-block-description"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('home:home_view_calendar_minimum_number_of_nights', {
                                        name: this.props.home.get('user').get('first_name'),
                                        number: minimumNumberOfNights
                                    })
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="home-calendar">
                    <CalendarCaptionHomeView
                        key="calendar-caption"
                        isOwner={this.props.isMyHome}
                        userFirstName={this.props.home.get('user').get('first_name')}
                        showReserved={this.props.hasReservedAvailability}
                    />
                    <div className="calendar-container">
                        <BlockCalendar
                            nbMonths={this.state.nbMonths}
                            availabilities={this.props.availabilities}
                            viewOnly={true}
                            mode={'range'}
                            showBookingDetails={this.props.isMyHome}
                            classname={['homeblock']}
                            isOwner={this.props.isMyHome}
                        />
                        {(() => {
                            if (this.props.availabilities.length === 0) {
                                // Display a message to tell the user there are no available periods
                                return (
                                    <div className="no-availabilities-overlay">
                                        <div className="no-availabilities-message">
                                            {(() => {
                                                if (this.props.isMyHome) {
                                                    const htmlMessage = {
                                                        __html: i18n.t(
                                                            'home:calendar.no_availabilities_my_home',
                                                            {
                                                                start_link: `<a href="${i18n.t(
                                                                    'url:home_edit',
                                                                    {
                                                                        id: this.props.homeId
                                                                    }
                                                                )}#tab_calendar">`,
                                                                end_link: '</a>'
                                                            }
                                                        )
                                                    };
                                                    return <span dangerouslySetInnerHTML={htmlMessage} />;
                                                } else {
                                                    return i18n.t('home:calendar.no_availabilities', {
                                                        firstname: this.props.home
                                                            .get('user')
                                                            .get('first_name')
                                                    });
                                                }
                                            })()}
                                        </div>
                                    </div>
                                );
                            } else if (this.state.nbMonths < this.maxNbMonths) {
                                return (
                                    <button className="btn-action-large-v2" onClick={this.showMoreMonths}>
                                        {i18n.t('home:calendar.show_more_months')}
                                    </button>
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}

export function hasReservedAvailability(availabilities) {
    if (Array.isArray(availabilities)) {
        return availabilities.some((availability) => availability.get('type') === Availability.BOOKED.type);
    }
    return false;
}

export function isMyHome(homes, homeId) {
    if (Array.isArray(homes)) {
        return homes.some((home) => home.id == homeId);
    }
    return false;
}

export const mapStateToProps = (state, ownProps) => {
    const home = state.loadedHomes[ownProps.homeId];
    const availabilities = state.availabilities[ownProps.homeId];
    return {
        home,
        availabilities,
        isMyHome: isMyHome(state.homes, ownProps.homeId)
    };
};

export default connect(mapStateToProps)(HomeViewCalendar);
