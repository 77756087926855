import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18n from '../../../i18n';

import { fetchAvailabilities } from '../../../actions/availabilities';
import { fetchHomes } from '../../../actions/homes';
import { fetchTopMenuNotifications } from '../../../actions/notifications';
import { fetchUser } from '../../../actions/user';

import Home from '../../../models/Home';
import Notification from '../../../models/Notification';
import User from '../../../models/User';

import Analytics from '../../../utils/analytics';
import Loading from '../../common/Loading';
import MenuProfileHome from './MenuProfileHome';
import MenuProfileUser from './MenuProfileUser';

import { Text, Button, Icon, Divider } from '@homeexchange/design';
import Availability from '../../../models/Availability';
import { HEADER_MENU_TRACKING_DATA_TEXT, trackHeaderMenu } from '../../../utils/utils-ts';

const MenuProfile = ({
    user,
    homes,
    availabilities,
    fetchUser,
    fetchHomes,
    fetchTopMenuNotifications,
    fetchAvailabilities,
    topMenuNotifications
}) => {
    const [menuStyle, setMenuStyle] = useState({});
    const menuRef = useRef(null);

    const onResize = useCallback(
        _.debounce(() => {
            resize();
        }, 50),
        []
    );

    const resize = useCallback(() => {
        if (menuRef.current && document.getElementById('head-nav-band')) {
            const maxHeight = window.innerHeight - document.getElementById('head-nav-band').offsetHeight;
            if (maxHeight !== menuStyle.maxHeight) {
                setMenuStyle({
                    maxHeight,
                    overflowY: 'auto'
                });
            }
        }
    }, [menuStyle.maxHeight]);

    const update = useCallback(() => {
        fetchUser();
        fetchHomes();
        fetchTopMenuNotifications();
        fetchAvailabilities();
    }, [fetchUser, fetchHomes, fetchTopMenuNotifications, fetchAvailabilities]);

    const addHomeHandler = () => {
        trackHeaderMenu(HEADER_MENU_TRACKING_DATA_TEXT.addHome);
    };

    useEffect(() => {
        if (user) {
            const addFirtHomeElmt = document.querySelector('#add-first-home');
            const addHomeElmt = document.querySelector('#add-home');
            const favoriteHomesListing = document.querySelector('#favorite-homes-listing');
            addFirtHomeElmt?.addEventListener('click', addHomeHandler);
            addHomeElmt?.addEventListener('click', addHomeHandler);
            favoriteHomesListing?.addEventListener('click', getTrackingData);

            return () => {
                addFirtHomeElmt?.removeEventListener('click', addHomeHandler);
                addHomeElmt?.removeEventListener('click', addHomeHandler);
                favoriteHomesListing?.removeEventListener('click', getTrackingData);
            };
        }
    }, [user]);

    useEffect(() => {
        updateTopMenuBadge();
        resize();
        window.addEventListener('resize', onResize);
        document.addEventListener('refresh_top_menu_profile', update);

        return () => {
            window.removeEventListener('resize', onResize);
            document.removeEventListener('refresh_top_menu_profile', update);
        };
    }, [onResize, update, resize]);

    useEffect(() => {
        updateTopMenuBadge();
    });

    const updateTopMenuBadge = useCallback(() => {
        if (!menuRef.current || !user || !topMenuNotifications) {
            return;
        }
        const badgeNb = topMenuNotifications.get('profile')
            ? menuRef.current.querySelectorAll('.top-menu-messages').length >
              topMenuNotifications.get('profile').nb_notification
                ? menuRef.current.querySelectorAll('.top-menu-messages').length
                : topMenuNotifications.get('profile').nb_notification
            : menuRef.current.querySelectorAll('.top-menu-messages').length;
        const badgeProfil = document.getElementById('badge-profil');
        badgeProfil.setAttribute('data-value', badgeNb);
        badgeProfil.style.display = badgeNb === 0 ? 'none' : 'block';
        badgeProfil.innerHTML = badgeNb;
    }, [topMenuNotifications, user]);

    const isSubscriptionExpired = useCallback(() => {
        const profile = topMenuNotifications?.get('profile');
        return profile?.my_plan?.reason === Notification.SUBSCRIPTION_EXPIRED;
    }, [topMenuNotifications]);

    const getTrackingData = useCallback(() => {
        const path = window.location.pathname;
        const firstTwoElements = path.split('/').slice(1, 3);

        const eventHandleFavorites = {
            action: 'click',
            text: 'header_favorites_homes',
            area: firstTwoElements.join(' ')
        };

        Analytics.trackGTM('HandleFavorites', { event_data: eventHandleFavorites });
        window.location.href = i18n.t('user:favorites.list.url');
    }, []);

    if (!user) {
        return (
            <ul
                ref={menuRef}
                className="dropdown-menu scrollable-menu accout"
                role="menu"
                id="profil_elt"
                style={menuStyle}
            >
                <Loading />
                <li role="menuitem">
                    <a className="link-top-profile item-link" href={i18n.t('url:logout.url')}>
                        <div className="item">
                            <div className="dropdown-title">
                                <Icon size="2xl" name="button-power"></Icon>
                                <span>{i18n.t('user:logout')}</span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        );
    }

    const noHomeCreated = !homes || homes.length === 0;

    return (
        <ul
            ref={menuRef}
            className="dropdown-menu scrollable-menu account"
            role="menu"
            id="profil_elt"
            style={menuStyle}
        >
            <li className="dropdown-section">
                <Text isBold className="title-section">
                    {i18n.t('home:top-menu-homes')}
                </Text>
                <ul role="menu">
                    {homes &&
                        homes.map((home) => (
                            <MenuProfileHome
                                key={home.id}
                                home={home}
                                user={user}
                                availabilities={availabilities?.[home.id]}
                                single={homes.length <= 1}
                                notifications={topMenuNotifications}
                            />
                        ))}
                    {!user.get('is_he_collection') && (
                        <>
                            {noHomeCreated && (
                                <li role="menuitem" id="add-first-home">
                                    <a
                                        className="link-top-profile item-link"
                                        href={i18n.t('home:home.create.url')}
                                    >
                                        <div className="item">
                                            <div className="dropdown-title">
                                                <Icon size="2xl" name="header-profile-home" />
                                                <span>{i18n.t('fillingpanel:my_home')}</span>
                                            </div>
                                        </div>
                                        <div className="section-status-container">
                                            <Text
                                                color="secondary"
                                                size="sm"
                                                isBold
                                                className="top-menu-messages"
                                            >
                                                {i18n.t('home:top-menu-earn-message')}
                                            </Text>
                                        </div>
                                    </a>
                                </li>
                            )}
                            <li role="menuitem" className="add-home-btn" id="add-home">
                                <Button
                                    href={i18n.t('home:home.create.url')}
                                    color="hollow"
                                    isFullWidth
                                    icon="plus-sign"
                                    iconSize="2xl"
                                >
                                    {i18n.t('home:create_a_home')}
                                </Button>
                            </li>
                        </>
                    )}
                </ul>
            </li>
            <Divider className="dropdown-divider" />
            <li className="dropdown-section">
                <Text isBold className="title-section">
                    {i18n.t('home:top-menu-favorites')}
                </Text>
                <ul role="menu">
                    <li role="menuitem" id="favorite-homes-listing">
                        <a className="link-top-profile item-link" href={i18n.t('user:favorites.list.url')}>
                            <div className="item">
                                <div className="dropdown-title">
                                    <Icon size="2xl" name="heart" />
                                    <span>{i18n.t('user:favorites.menuitem')}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a
                            className="link-top-profile item-link"
                            href={i18n.t('user:theylovemyhome.list.url')}
                        >
                            <div className="item">
                                <div className="dropdown-title">
                                    <Icon size="2xl" name="header-profile-they-love-my-home"></Icon>
                                    <span>{i18n.t('dashboard:they_love_my_home_title')}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a className="link-top-profile item-link" href={i18n.t('url:myexchanges')}>
                            <div className="item">
                                <div className="dropdown-title">
                                    <Icon size="2xl" name="key"></Icon>
                                    <span>{i18n.t('home:my_exchanges')}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>
            <Divider className="dropdown-divider" />
            <li className="dropdown-section">
                <Text isBold className="title-section">
                    {i18n.t('home:top-menu-account')}
                </Text>
                <ul>
                    <MenuProfileUser user={user} notifications={topMenuNotifications} />
                    <li role="menuitem" className="my-plan-link">
                        <a className="link-top-profile item-link" href={i18n.t('url:my-plan.url')}>
                            <div className="item">
                                <div className="dropdown-title">
                                    <Icon size="2xl" name="credit-card"></Icon>
                                    <span>
                                        {user.get('is_he_collection')
                                            ? i18n.t('collection:menu-profile.collection.membership')
                                            : i18n.t('subscription:my_subscription_plan')}
                                    </span>
                                </div>
                            </div>
                            <div>
                                {isSubscriptionExpired() && (
                                    <div className="section-status-container">
                                        <Text
                                            color="secondary"
                                            size="sm"
                                            isBold
                                            className="top-menu-messages"
                                        >
                                            {i18n.t('user:optimal.expired')}
                                        </Text>
                                    </div>
                                )}
                                {user.isAutoRenew() &&
                                    topMenuNotifications.isDefaultPaymentMethodExpired() && (
                                        <div className="section-status-container">
                                            <Text
                                                color="secondary"
                                                size="sm"
                                                isBold
                                                className="top-menu-messages"
                                            >
                                                {i18n.t('subscription:my-plan.payment_method_expired')}
                                            </Text>
                                        </div>
                                    )}
                                {!isSubscriptionExpired() && !user.isSubscriber() && (
                                    <div className="section-status-container">
                                        <Text
                                            color="secondary"
                                            size="sm"
                                            isBold
                                            className="top-menu-messages"
                                        >
                                            {i18n.t('user:activate-unlimited')}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a className="link-top-profile item-link" href={i18n.t('common:reward.url.5')}>
                            <div className="item">
                                <div className="dropdown-title">
                                    <Icon size="2xl" name="megaphone"></Icon>
                                    <span>{i18n.t('user:reward.label.5')}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a
                            className="link-top-profile item-link"
                            href={i18n.t('url:settings.url', { id: user.id })}
                        >
                            <div className="item">
                                <div className="dropdown-title">
                                    <Icon size="2xl" name="header-profile-settings"></Icon>
                                    <span>{i18n.t('user:settings')}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a
                            className="link-top-profile item-link"
                            href={`${i18n.t('url:logout.url')}${
                                user.get('is_he_collection') ? '-collection' : ''
                            }`}
                        >
                            <div className="item">
                                <div className="dropdown-title">
                                    <Icon size="2xl" name="button-power"></Icon>
                                    <span>{i18n.t('user:logout')}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    );
};

MenuProfile.propTypes = {
    user: PropTypes.instanceOf(User),
    homes: PropTypes.arrayOf(PropTypes.instanceOf(Home)),
    availabilities: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(Availability))),
    fetchUser: PropTypes.func.isRequired,
    fetchHomes: PropTypes.func.isRequired,
    fetchTopMenuNotifications: PropTypes.func.isRequired,
    fetchAvailabilities: PropTypes.func.isRequired,
    topMenuNotifications: PropTypes.instanceOf(Notification)
};

const mapStateToProps = (state) => ({
    user: state.user,
    homes: state.homes.length > 0 ? state.homes : null,
    availabilities: state.availabilities,
    topMenuNotifications: state.topMenuNotifications
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: bindActionCreators(fetchUser, dispatch),
    fetchHomes: bindActionCreators(fetchHomes, dispatch),
    fetchTopMenuNotifications: bindActionCreators(fetchTopMenuNotifications, dispatch),
    fetchAvailabilities: bindActionCreators(fetchAvailabilities, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuProfile);
