import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';

const CollectionLabel = ({ user, home }) => {
    if (!user || !home) {
        return null;
    }

    const isEligibleOrCollection =
        (user?.isUserEligibleCollection() || user?.isCollection()) &&
        (home.get('is_he_collection') || home.get('is_collection'));

    return isEligibleOrCollection ? (
        <div className="label-collection">{i18n.t('home:label_collection')}</div>
    ) : null;
};

CollectionLabel.propTypes = {
    user: PropTypes.shape({
        isUserEligibleCollection: PropTypes.func,
        isCollection: PropTypes.func,
        id: PropTypes.number
    }),
    home: PropTypes.shape({
        get: PropTypes.func
    })
};

export default CollectionLabel;
