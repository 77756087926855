var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import FeatureModels from '../../../../models/Feature';
import { CheckboxContained, CheckboxContainedGroup } from '@homeexchange/design';
import styles from './surrounding-tags.module.scss';
import i18n from '../../../../i18n';
var MAX_SELECTED_COUNT = 2;
export var SurroundingTags = function (_a) {
    var onUpdate = _a.onUpdate, _b = _a.surrounding, surrounding = _b === void 0 ? [] : _b, _c = _a.buttonWithDescription, buttonWithDescription = _c === void 0 ? false : _c, _d = _a.useNumericValue, useNumericValue = _d === void 0 ? false : _d, labelContent = _a.labelContent, helperContent = _a.helperContent;
    return (React.createElement("div", { className: styles['modal-surroundings-section'] },
        React.createElement(CheckboxContainedGroup, __assign({ collapsible: true, collapsed: true, showLines: MAX_SELECTED_COUNT, showMore: i18n.t('home:show-more'), showLess: i18n.t('home:show-less'), buttonWithDescription: buttonWithDescription }, (labelContent && { labelContent: labelContent }), (helperContent && { helperContent: helperContent })), FeatureModels.FEATURES.filter(function (feature) { return feature.category === FeatureModels.SURROUNDING_TAGS; }).map(function (feature) {
            var value = useNumericValue ? feature.value : feature.icon;
            return (React.createElement(CheckboxContained, __assign({ name: useNumericValue ? 'feature[surrounding][]' : 'surrounding', type: "checkbox", key: "surrounding-".concat(feature.icon), label: i18n.t("home:feature-surrounding-".concat(feature.icon)), onChange: onUpdate, value: value, icon: feature.icon, checked: surrounding.includes(value), id: "gtm-track-".concat(feature.icon) }, (buttonWithDescription && { description: i18n.t(feature.description) }))));
        }))));
};
export default SurroundingTags;
