import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import Availability from '../../../models/Availability';
import CalendarUtils from '../../../utils/CalendarUtils';

export default class CalendarSelectAvailabilitiesPopup extends React.PureComponent {
    static propTypes = {
        popover: PropTypes.instanceOf($).isRequired,
        dateRange: PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)])
        ).isRequired,
        clickHandler: PropTypes.func.isRequired,
        mergedPeriods: PropTypes.arrayOf(Date),
        type: PropTypes.string,
        overlapsAvailability: PropTypes.bool,
        minimumNights: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.handlePass = this.handlePass.bind(this);
        this.state = { view: 'init' };
    }

    componentDidMount() {
        this.props.popover.popover('show');
    }

    componentDidUpdate() {
        this.props.popover.popover('show');
    }

    UNSAFE_componentWillMount() {
        const { dateRange, mergedPeriods } = this.props;

        if (!mergedPeriods) {
            return false;
        }

        if (dateRange[0].isSame(mergedPeriods[0])) {
            this.setState({ emptyNight: dateRange[1] });
        } else if (dateRange[1].isSame(mergedPeriods[1])) {
            this.setState({ emptyNight: dateRange[0].add(-1, 'days') });
        }
    }

    popupText() {
        const { mergedPeriods } = this.props;
        if (this.state.emptyNight) {
            return i18n.t('common:calendar.night.in.between', {
                d1: `<b>${this.state.emptyNight.format('D MMMM')}</b>`,
                d2: `<b>${this.state.emptyNight.add(1, 'days').format('D MMMM')}</b>`
            });
        } else {
            return i18n.t('common:calendar.night.in.between_plural', {
                count: 2,
                d1: `<b>${mergedPeriods[0].format('D MMMM')}</b>`,
                d2: `<b>${mergedPeriods[1].format('D MMMM')}</b>`
            });
        }
    }

    handlePass() {
        this.setState({ view: 'pass' });
    }

    showNumberOfNightMessage() {
        const { dateRange, minimumNights } = this.props;
        const numberOfNightsFromCalendar = CalendarUtils.calculateNumberOfNightsOfSelectedDates(dateRange);
        return numberOfNightsFromCalendar < minimumNights;
    }

    render() {
        if (this.state.view === 'pass' || this.props.mergedPeriods === null) {
            return (
                <div>
                    <div className="popover-range-title">
                        {i18n.t('home:calendar.new_range_popup_message')}
                    </div>
                    <br />
                    <div className="buttons-no-border">
                        <button
                            type="button"
                            className="available"
                            onClick={this.props.clickHandler(Availability.AVAILABLE.type)}
                        >
                            {i18n.t('home:availability_popup.any_type')}
                        </button>
                        <button
                            type="button"
                            className="reciprocal"
                            onClick={this.props.clickHandler(Availability.RECIPROCAL.type)}
                        >
                            {i18n.t('home:availability_popup.reciprocal')}
                        </button>
                        <button
                            type="button"
                            className="look-for-guests"
                            onClick={this.props.clickHandler(Availability.NON_RECIPROCAL.type)}
                        >
                            {i18n.t('home:availability_popup.non_reciprocal')}
                        </button>
                    </div>
                    <div className="buttons">
                        {this.showNumberOfNightMessage() && (
                            <p className="text-error-input p-t-5 min-nights-info">
                                <span className="icon-error-registration" />
                                {i18n.t('home:min-nb-nights-info-popup')}
                            </p>
                        )}
                    </div>
                    {this.props.overlapsAvailability && (
                        <button
                            className="delete-period"
                            onClick={this.props.clickHandler(Availability.UNAVAILABLE.type)}
                        >
                            <i className="icon-home-bin"></i>
                            {i18n.t('home:calendar.delete_range_popup')}
                        </button>
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        className="popover-range-title"
                        dangerouslySetInnerHTML={{ __html: this.popupText() }}
                    ></div>
                    <br />
                    <div className="buttons-merge">
                        <button
                            className="join-periods"
                            onClick={this.props.clickHandler(this.props.type, this.props.mergedPeriods)}
                        >
                            {i18n.t('common:calendar.night.merge.period')}
                        </button>
                        <br />
                        <button className="pass-join-periods" onClick={this.handlePass}>
                            {i18n.t('common:calendar.night.keep.separated')}
                        </button>
                    </div>
                </div>
            );
        }
    }
}
