import Api from '../api/Api';

import SavedSearches from '../models/Alert';

export const LOAD_SAVED_SEARCHES = 'LOAD_SAVED_SEARCHES';
export const ADD_SAVED_SEARCH = 'ADD_SAVED_SEARCH';
export const PATCH_SAVED_SEARCH = 'PATCH_SAVED_SEARCH';
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH';

export function loadAlerts(savedSearches) {
    savedSearches = savedSearches.map((savedSearch) => new SavedSearches(savedSearch));
    return {
        type: LOAD_SAVED_SEARCHES,
        payload: savedSearches
    };
}

export function patchAlert(savedSearch) {
    return {
        type: PATCH_SAVED_SEARCH,
        payload: savedSearch
    };
}

export function saveAlert(savedSearch) {
    return {
        type: ADD_SAVED_SEARCH,
        payload: savedSearch
    };
}

export function deleteAlert(savedSearch) {
    return {
        type: DELETE_SAVED_SEARCH,
        payload: savedSearch
    };
}

export function fetchSavedSearches(userId, orderBy, limit = 1000, offset) {
    return (dispatch) =>
        Api.Alert.get(
            {
                user: userId,
                status: [SavedSearches.STATUS_ENABLED, SavedSearches.STATUS_DISABLED]
            },
            orderBy,
            limit,
            offset,
            2
        ).then((alerts) => dispatch(loadAlerts(alerts)));
}

export function mySavedSearches(orderBy, limit = 1000, offset) {
    return (dispatch) =>
        Api.Alert.me(
            {
                status: [SavedSearches.STATUS_ENABLED, SavedSearches.STATUS_DISABLED]
            },
            orderBy,
            limit,
            offset,
            2
        ).then((alerts) => dispatch(loadAlerts(alerts)));
}

export function updateSavedSearch(search, changes) {
    return (dispatch) =>
        Api.Alert.patch(search, changes).then((response) => {
            const alert = new SavedSearches(response);
            dispatch(patchAlert(alert));
            return alert;
        });
}

export function removeSearch(search) {
    return (dispatch) => Api.Alert.remove(search).then(() => dispatch(deleteAlert(search)));
}

export function addSearch(newSearch, version = false) {
    return (dispatch) =>
        Api.Alert.save(newSearch, version).then((response) => {
            const alert = new SavedSearches(response);
            dispatch(saveAlert(alert));
            return alert;
        });
}
