import Model from './Model';

export default class Notification extends Model {
    static PROFILE_REASON_COMPLETE_YOUR_PROFILE = 'top-menu.complete-your-profile';
    static PROFILE_REASON_COMPLETE_YOUR_PROFILE_FIRST_TIME = 'top-menu.complete-your-profile-first-time';
    static HOME_REASON_OFFLINE = 'top-menu.offline';
    static HOME_REASON_COMPLETE_YOUR_HOME = 'top-menu.complete-your-home';
    static HOME_REASON_COMPLETE_YOUR_HOME_FIRST_TIME = 'top-menu.complete-your-home-first-time';
    static HOME_REASON_COMPLETE_YOUR_HOME_EMPTY_CALENDAR = 'top-menu.empty-calendar';
    static HOME_REASON_UPDATE_CALENDAR_TOO_OLD = 'top-menu.update-calendar-too-old';
    static HOME_REASON_NEW_CALENDAR_TYPES = 'top-menu.calendar-new-types-tour';
    static SUBSCRIPTION_EXPIRED = 'top-menu.subscription-is-expired';
    static DEFAULT_PAYMENT_METHOD_EXPIRED = 'top-menu.default_payment_method_expired';

    getProfileNotification() {
        return this.get('profile') ? this.get('profile').my_profile : null;
    }

    isDefaultPaymentMethodExpired() {
        const defaultPaymentNotification = this.get('profile')
            ? this.get('profile').my_default_payment
            : null;

        if (!defaultPaymentNotification) {
            return false;
        }

        return defaultPaymentNotification === Notification.DEFAULT_PAYMENT_METHOD_EXPIRED;
    }

    getHomeNotification(homeId) {
        const homesNotification = this.get('profile') ? this.get('profile').my_homes : null;
        if (!homesNotification) {
            return;
        }
        return homesNotification.find((n) => n.id == homeId);
    }
}
