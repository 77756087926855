import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { connect, Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { RouletteProvider } from '@homeexchange-legacy/react-roulette';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';

import Api from './api/Api';
import Mobile from './utils/Mobile';
import { replaceElement, insertElementBefore, appendElementBefore } from './utils/react';
import Storage from './utils/Storage';

import i18n from './i18n';

import store from './store';

import { fetchTopMenuNotifications } from './actions/notifications';

import { CurrencyProvider } from './components/currency';
import AnalyticsProvider from './components/analytics/AnalyticsProvider';
import useAnalytics from './components/analytics/useAnalytics';
import AuthProvider from './components/auth/AuthProvider';
import UserContext from './components/auth/UserContext';
import EmailBanner from './components/user/verification/EmailBanner';
import PhoneBanner from './components/user/verification/PhoneBanner';
import Headband from './components/headband/Headband';
import Icons from '../src/utils/Icons';
import CurrencySwitcher from './components/layout/CurrencySwitcher';
import LanguageSwitcher from './components/layout/LanguageSwitcher';
import MenuProfile from './components/layout/header/MenuProfile';
import MenuGuestpoints from './components/layout/header/MenuGuestpoints';
import Signin from './components/popup/Signin';
import Signup from './components/popup/Signup';
import SearchBar from './components/search/SearchBar';
import SearchProvider from './components/search/SearchProvider';
import Stars from './components/rating/Stars';
import TermsOfUsePopup, { termsOfUseTourName } from './components/popup/TermsOfUsePopup';
import UploadListWithCaption from './components/upload/UploadListWithCaption';
import UploadProfile from './components/profile/Upload';
import Analytics from './utils/analytics';
import DiscoverCollection from './components/footer/discover';
import Iterable from './utils/iterable';
import LHSHelpMenuItem from './components/layout/header/LHSHelpMenuItem';

// Modernizr
import '../modernizr';
import '@homeexchange/design/assets/style.css';
import '@homeexchange/design/global.css';

// Add CSS class to tell if device handles hover or not
jQuery(document).on('mq4hsChange', (e) => {
    if (e.trueHover) {
        jQuery(document.documentElement).toggleClass('support-hover', true);
    } else {
        jQuery(document.documentElement).toggleClass('no-support-hover', true);
    }
});
require('mq4-hover-shim');

// jQuery
import $ from 'jquery';

// Hopscotch
import hopscotch from 'hopscotch';
window.hopscotch = hopscotch;
import 'hopscotch/dist/css/hopscotch.min.css';

// Tooltipster
import 'tooltipster';
import 'tooltipster/dist/css/tooltipster.bundle.min.css';
import 'tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-light.min.css';

// SweetAlert
import sweetAlert from './utils/sweetAlert';
window.sweetAlert = sweetAlert;

// import lib's css
import 'kalendae/build/kalendae.css';
import 'nouislider/distribute/nouislider.min.css';
// import 'emojione-picker/css/picker.css';

// Moment
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Tour from './utils/Tour';
import useGTM from './components/analytics/useGTM';
import TrackingTwig from './tracking-twig';

import { fetchingStatuses } from './reducers/loading';
const moment = extendMoment(Moment);
moment.locale(i18n.language);

// Facebook SDK
window.fbAsyncInit = function () {
    const appId = $('meta[property="fb:app_id"]').attr('content');
    window.FB.init({
        appId,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v3.2' // use graph api version 3.2
    });
};

$(document).ready(() => {
    Mobile.changeLinks();
});

window.gtg = Object.assign({}, window.gtg);

// expose API
window.Api = Api;

if (window.gtg.shouldClearLocalStorage) {
    Storage.clearLocalStorage();
}

const isconnected = document.getElementsByClassName('layout-login').length > 0;

const DefaultRoulette = connect((state) => ({ user: state.user }))(({ user }) =>
    user ? <RouletteProvider endpoint={process.env.ROULETTE_HOST} userID={user.id} /> : false
);

const iterable = Iterable.getIterableInstance();

// Track page viewed
function PageViewTrack({ user, paymentMethods, loading, auth }) {
    const { fetchPageView } = useGTM(user, paymentMethods);
    const { trackSpinalCord, trackGTM } = useAnalytics();
    const [userEmail, setUserEmail] = React.useState(null);

    React.useEffect(() => {
        const allLoading =
            loading.userFetchingStatus === fetchingStatuses.FINISHED &&
            loading.loyaltyFetchingStatus === fetchingStatuses.FINISHED &&
            loading.paymentMethodsFetchingStatus === fetchingStatuses.FINISHED;

        // if user is unlogged OR he's logged and his informations are loaded we want to track
        if (!auth.accessToken || allLoading) {
            trackGTM('PageView', fetchPageView());
        }

        setUserEmail(user?.get('email'));
    }, [auth, loading, user]);

    React.useEffect(() => {
        // send spinalcord page viewed tracking
        try {
            trackSpinalCord('page-viewed');
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }

        // send myPlan, payment page viewed to iterable
        if (userEmail) {
            const { setEmail } = iterable.getIterableAuthObject();
            setEmail(userEmail)
                .then(() => {
                    Iterable.fetchInBrowserMessages()
                        .request()
                        .catch((error) => console.error('[FETCHING IN BROWER MESSAGES]:', error));
                })
                .catch((error) => console.error('[ITERABLE USER IDENTIFICATION]:', error));

            const currentUrl = window.location.pathname;
            let trackEvent = null;
            if (currentUrl.includes('/my-plan')) {
                trackEvent = 'MyPlanPageViewed';
            } else if (
                currentUrl.includes('/subscription/payment') ||
                currentUrl.includes('/subscription/renew')
            ) {
                trackEvent = 'ViewedPaymentPage';
            }
            if (trackEvent) {
                try {
                    Analytics.trackIterable('ViewedPaymentPage', {
                        email: userEmail,
                        pageName: currentUrl
                    });
                } catch (error) {
                    console.error('Error while tracking Iterable', error);
                }
            }
        }
    }, [userEmail]);

    return false;
}

const ConnectedPageViewTrack = connect((state) => ({
    auth: state.auth,
    user: state.user,
    paymentMethods: state.paymentMethods,
    loading: state.loading
}))(PageViewTrack);

// manage token and load data for user connected
const root = createRoot(document.createElement('div'));
root.render(
    <Provider store={store}>
        <DefaultRoulette />
        <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
            <ConnectedPageViewTrack />
            <TrackingTwig />
        </AnalyticsProvider>
        <AuthProvider>
            <UserContext />
        </AuthProvider>
    </Provider>
);

// Initialize Signin Button
const signin = document.getElementById('signin');
if (signin) {
    insertElementBefore(
        <Provider store={store}>
            <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                <Signin />
            </AnalyticsProvider>
        </Provider>,
        signin
    ).then(() => {
        // show modal when click on sign in button
        $('body').on('click', '.btn-signin', () => {
            document.dispatchEvent(new CustomEvent('open_signin_popup'));
        });
        if (window.location.hash === '#signin') {
            document.dispatchEvent(new CustomEvent('open_signin_popup'));
        }
    });
}

const btnContactCollection = document.getElementById('contact-collection');
if (btnContactCollection) {
    const btnsContact = document.querySelectorAll('.btn-contact-collection');
    btnsContact.forEach((btnContact) =>
        btnContact.addEventListener('click', () => {
            sweetAlert({
                content: (
                    <div className="collection-popup">
                        <div className="collection-popup-header">
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <div
                                className="icon-collection-close"
                                onClick={() => {
                                    sweetAlert.close();
                                }}
                            >
                                {Icons.transform(2)}
                            </div>
                        </div>
                        <div className="collection-popup-content">
                            <h4 className="collection-popup-title">{i18n.t('collection:need-help')}</h4>
                            <p
                                className="collection-popup-text"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('collection:contact-us-popup-text')
                                }}
                            />
                        </div>
                        <div className="collection-popup-buttons">
                            <button className="btn btn-primary btn-contact-collection-popup">
                                <i className="icon-phone_new icon" />
                                <a
                                    href="https://homeexchangecollection.setmore.com/bookappointment"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {i18n.t('collection:book-a-call')}
                                </a>
                            </button>
                            <button className="btn btn-primary btn-contact-collection-popup">
                                <i className="icon-header-message-new icon" />
                                <a
                                    href={`mailto:joincollection@homeexchange.com?subject=${i18n.t(
                                        'collection:object-mail-contact-collection'
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {i18n.t('collection:send-mail')}
                                </a>
                            </button>
                        </div>
                    </div>
                ),
                closeOnClickOutside: true,
                buttons: false,
                className: 'collection-popup-modal'
            });
        })
    );
}

// init guest point tour for collection user
$('.gp-number').ready(() => {
    if (
        $('body').hasClass('he-collection') &&
        window.location.pathname !== '/collection/subscription/payment' &&
        window.location.pathname !== '/collection'
    ) {
        const { user } = store.getState();
        Tour.isViewed(termsOfUseTourName).then((isViewed) => {
            if (isViewed && user?.isFromApplicationFlowCollection()) {
                Tour.init(Tour.collectionGPTour.id);
            }
        });
    }
});

// Initialize Signup Button
const signup = document.getElementById('signup');
if (signup) {
    insertElementBefore(
        <CookiesProvider>
            <Provider store={store}>
                <CurrencyProvider>
                    <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                        <Signup />
                    </AnalyticsProvider>
                </CurrencyProvider>
            </Provider>
        </CookiesProvider>,
        signup
    ).then(() => {
        // show modal when click on sign in button
        $('body').on('click', '.btn-signup, [data-target="#mySignUpModal"], [href="#user-signup"]', (e) => {
            const version = $(e.currentTarget).data('context');
            document.dispatchEvent(
                new CustomEvent('open_signup_popup', {
                    detail: { version }
                })
            );
        });
        if (window.location.hash === '#signup' || window.location.hash === '#user-signup') {
            document.dispatchEvent(new CustomEvent('open_signup_popup'));
        }
    });
}

const OpenSignupPoppup = withRouter(({ location }) => {
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('showSignupPopup') === 'true') {
            document.dispatchEvent(new CustomEvent('open_signup_popup'));
        }
    }, [location.search]);

    return null;
});

createRoot(document.createElement('div')).render(
    <Provider store={store}>
        <Router>
            <OpenSignupPoppup />
        </Router>
    </Provider>
);

// render search bars
const searchBars = document.querySelectorAll('.search-bar');
if (searchBars && searchBars.length > 0) {
    Array.prototype.forEach.call(searchBars, (searchBar) => {
        createRoot(searchBar).render(
            <Provider store={store}>
                <Router>
                    <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                        <SearchProvider>
                            <SearchBar />
                        </SearchProvider>
                    </AnalyticsProvider>
                </Router>
            </Provider>
        );
    });
}

// render search bar
const headerGuestpoint = document.getElementById('guestpoint_elt');
if (headerGuestpoint) {
    replaceElement(
        <Provider store={store}>
            <MenuGuestpoints />
        </Provider>,
        headerGuestpoint
    );
}

// render LHS FAQ help menu
const lhsFAQHelp = document.getElementById('lhs-faq');
if (isconnected && lhsFAQHelp) {
    createRoot(lhsFAQHelp).render(
        <Provider store={store}>
            <LHSHelpMenuItem />
        </Provider>
    );
}

// render header profile
const hearderProfile = document.getElementById('profil_elt');
if (hearderProfile) {
    replaceElement(
        <Provider store={store}>
            <MenuProfile />
        </Provider>,
        hearderProfile
    ).then(() => {
        store.dispatch(fetchTopMenuNotifications());
    });
}

// Initialize Stars
const stars = document.querySelectorAll('.react-stars');
[].forEach.call(stars, (element) => {
    const nbStars = parseFloat(element.getAttribute('data-stars'));
    createRoot(element).render(<Stars stars={nbStars} />);
});

// Initialize Upload Profile
const uploadProfile = document.querySelector('#upload-profile');
if (uploadProfile) {
    createRoot(uploadProfile).render(
        <Provider store={store}>
            <UploadProfile user={store.getState().user} homes={store.getState().homes} />
        </Provider>
    );
}

// Initialize Upload Home
const uploadHome = document.querySelector('#upload-home');
if (uploadHome) {
    const homeId = parseInt(uploadHome.getAttribute('data-homeId'), 10);
    const segmentCategory = uploadHome.getAttribute('data-segmentCategory');
    const explanations = Boolean(uploadHome.getAttribute('data-explanations'));
    const minImages = parseInt(uploadHome.getAttribute('data-minImages'), 10);
    createRoot(uploadHome).render(
        <Provider store={store}>
            <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                <UploadListWithCaption
                    homeId={homeId}
                    minImages={minImages}
                    explanations={explanations}
                    segmentCategory={segmentCategory}
                />
            </AnalyticsProvider>
        </Provider>
    );
}

const headbandEl = document.getElementById('headband');
const doNotDisplayOnThisPage =
    document.getElementById('billing-layout') || // payment page
    document.getElementById('sponsorship-page') || // sponsorship page
    document.getElementById('user-settings') || // user setting page
    document.getElementById('user-edit-2') || // profile edit page
    document.getElementById('home-edit-2') || // home edit page
    document.getElementById('messaging') || // messaging page
    document.getElementById('collection-landing-page') || // landing page collection
    document.getElementById('block-sign-in-collection'); // block sign in collection page

if (headbandEl && !doNotDisplayOnThisPage) {
    // Do not display headband on the messaging
    createRoot(headbandEl).render(
        <CookiesProvider>
            <Provider store={store}>
                <CurrencyProvider>
                    <Headband />
                </CurrencyProvider>
            </Provider>
        </CookiesProvider>
    );
}

const languageSwitcher = document.getElementById('language-switcher');
if (languageSwitcher) {
    createRoot(languageSwitcher).render(<LanguageSwitcher />);
}

const currencySwitcher = document.getElementById('currency-switcher');
if (currencySwitcher) {
    createRoot(currencySwitcher).render(
        <CookiesProvider>
            <Provider store={store}>
                <CurrencyProvider>
                    <CurrencySwitcher />
                </CurrencyProvider>
            </Provider>
        </CookiesProvider>
    );
}

const discoverCollection = document.getElementById('discover-collection');
if (discoverCollection) {
    createRoot(discoverCollection).render(
        <CookiesProvider>
            <Provider store={store}>
                <DiscoverCollection />
            </Provider>
        </CookiesProvider>
    );
}

const discoverCollectionMobile = document.getElementById('discover-collection-mobile');
if (discoverCollectionMobile) {
    createRoot(discoverCollectionMobile).render(
        <CookiesProvider>
            <Provider store={store}>
                <DiscoverCollection displayOnMobile />
            </Provider>
        </CookiesProvider>
    );
}

const termsOfUsePopup = document.getElementById('terms-of-use-popup');
if (termsOfUsePopup) {
    // ISL-1922 - Do not display TermsOfUse popup on user settings page if from email
    const fromEmail = Boolean(window.location.search.match(/source=email/i));
    const userSettingsPage = Boolean(window.location.href.match(/\/user\/settings/i));
    const userEditPage = Boolean(window.location.href.match(/\/user\/edit/i));
    if (!(fromEmail && (userSettingsPage || userEditPage))) {
        createRoot(termsOfUsePopup).render(
            <Provider store={store}>
                <TermsOfUsePopup />
            </Provider>
        );
    }
}

appendElementBefore(
    <Provider store={store}>
        <React.Fragment>
            <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                <EmailBanner />
            </AnalyticsProvider>
            <PhoneBanner />
        </React.Fragment>
    </Provider>,
    document.body
);

// Popup can be disabled by removing the environment variable
if (window && window.gtg && window.gtg.enableGPPopup) {
    const connectedLayout = document.getElementsByClassName('layout-login');
    // Only show on connected pages
    if (connectedLayout.length > 0) {
        const { user } = store.getState();
        if (user && user.get('tours') && !_.findWhere(user.get('tours'), { tour: 'gp_change_popup' })) {
            // Check that the user hasn't seen the popup about GP change already
            const content = (
                <div>
                    {i18n.t('common:gp_popup_description')}
                    <br />
                    <a href={i18n.t('url:blog_gp_popup')} target="_blank" rel="noopener noreferrer">
                        {i18n.t('common:seemore')}
                    </a>
                </div>
            );
            sweetAlert({
                title: i18n.t('common:gp_popup_title'),
                content,
                icon: 'info',
                className: 'gp-popup',
                closeOnEsc: false,
                closeOnClickOutside: false,
                buttons: i18n.t('common:gp_popup_button')
            }).then(() => {
                Api.UserTour.setTourAsViewed('gp_change_popup');
            });
        }
    }
}
